/*
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-24 08:37:19
 */
import Mock from "mockjs"

// 所有的服务端接口在接收到请求后，均响应以下JSON格式

Mock.mock("/index.php/news-list","get",{
    code: 0,//错误码
    msg: "",//错误消息
    //data为具体的消息内容，如果code不为0，则必为null
    data: [
        {   
            id:1,
            url:  require("@/assets/img/News/newsImg.png"),
            title:'领导视察暖心怀  砺砺前行展未来',
            abstract: ` 2023年1月29日下午，潜江市市长盛文军及政府成员一行莅临东方久乐汽车电子（上海）股份有限公司潜江分公司，
            进行实地参观调研。公司董事长兼总经理高劲松、潜江分公司厂长张士良陪同市领导参观了办公区域、生产车间、厂区环境等地...`,
            release_time: '2023-01-29',
            subtitle: '--潜江市领导盛文军一行参观考察久乐电子',
            main_body_data:[
                {type:'text',center:`2023年1月29日下午，潜江市市长盛文军及政府成员一行莅临东方久乐汽车电子（上海）股份有限公司潜江分公司，进行实地参观调研。公司董事长兼总经理高劲松、
                潜江分公司厂长张士良陪同市领导参观了办公区域、生产车间、厂区环境等地。`},
                {type:'img',center:require("@/assets/img/News/newsImg.png")},
                {type:'text',center:`董事长高劲松就久乐电子厂区建设情况及公司发展规划向盛市长一行领导进行了汇报：厂区总占地面积100亩，一期项目于2021年12月投入使用；久乐电子立足企业品牌和产品优势，制订切实可行的发展战略，推动传统产业转型升级，助力新兴产业加快发展。
                随后，市领导陆续参观了公司的贴片车间、插件车间和装配车间，厂长张士良向参观领导详细介绍了生产线的整个生产操作流程、技术优势和生产力。`},
                {type:'img',center:require("@/assets/img/News/newsImg-1.png")},
                {type:'text',center:`最后，市领导对公司所取得的成绩和产生的经济效益及社会效益给与充分的肯定。盛市长表示，久乐电子能准确预判和把握市场动向，积极应变，稳产扩能，积极推进传统行业转型升级，构建良好产业发展生态，助力我市制造业高质量发展，对促进经济发展做出了突出贡献；他要求潜江领导班子稳中求进、循序渐进推动企业发展，要提升服务意识，积极解决企业在发展中遇到的困难和问题，为企业在潜江发展创造良好条件。`},
            ]

        },

        {   
            id:2,
            url: require("@/assets/img/News/newsImg2.png"),
            title:'振兴乡村经济，做强县域经济',
            abstract: ` 2023年1月30日石家庄市委书记张超超、马宇骏、李雪荣、张业等领导和新乐市委书记李明政等政府主要负责人来东方久乐汽车电子（上海）股份有限公司新乐分公司新厂区观摩考察，
            新乐分公司厂长赵一樊和新厂区负责人王志拥等人陪同参观视察...`,
            release_time: '2023-01-30',
            subtitle: '--2023年1月30日市委书记张超超来我司视察',
            main_body_data:[
                {type:'img',center:require("@/assets/img/News/newsImg2.png")},
                {type:'text',center:`2023年1月30日石家庄市委书记张超超、马宇骏、李雪荣、张业等领导和新乐市委书记李明政等政府主要负责人来东方久乐汽车电子（上海）股份有限公司新乐分公司新厂区观摩考察，
                新乐分公司厂长赵一樊和新厂区负责人王志拥等人陪同参观视察，厂长赵一樊向书记张超超一行详细介绍了公司的主要产品和合作厂家及公司的发展前景，新厂区负责人王志拥向张超超汇报了新厂区的建设情况：
                厂区占地31亩，项目投资4.1亿元，建筑面积3万平方米，工程建设进度情况：整体项目于2022年8月份开工建设，一期工程已完工于2023年7月投入使用，二期工程于2023年年底完工2024年3月份投产，基地建成后实现了上海和新乐基地合并，建成后预计年产600万套汽车电子控制单元，产值6亿元，税收2000余万元.`},
                {type:'img',center:require("@/assets/img/News/newsImg2-1.png")},
                {type:'text',center:`最后市委书记张超超对久乐电子产生的经济效益作出了高度的认可，并强调要重视中小微企业的发展，小企业也会有大作为。向领导班子指出要提高站位、认清形势，以高度紧迫感加快县域经济发展和全面推进乡村振兴。`},
                {type:'img',center:require("@/assets/img/News/newsImg2-2.png")},
                {type:'img',center:require("@/assets/img/News/newsImg2-3.png")},
                {type:'text',center:`在1月30日集中观摩结束后，全市县域经济暨乡村振兴工作推进会议在市委党校举行。市委书记张超超出席会议并讲话他强调，要全面贯彻党的二十大精神，认真落实省委十届三次全会和省“两会”部署，全力打好县域经济发展翻身仗，推进乡村振兴迈上新台阶，为建设现代化、国际化美丽省会城市提供强劲动力。`},
            ]

        },

        {   
            id:3,
            url: require("@/assets/img/News/newsImg3.png"),
            title:'2023年度研发团队团建活动',
            abstract: ` 为了感谢团队伙伴一直以来的努力付出，为了增强团队成员的互相协作及高度契合度，丰富伙伴们的工作生活，于2023年6月30日-7月1日特别安排了常熟（虞山、虞山景区、尚湖景区）两日团建旅游活动，具体情况回顾如下...`,
            release_time: '2023-07-03',
            subtitle: '--2023年6月30日公司团建',
            main_body_data:[
                {type:'text',center:`为了感谢团队伙伴一直以来的努力付出，为了增强团队成员的互相协作及高度契合度，丰富伙伴们的工作生活，于2023年6月30日-7月1日特别安排了常熟（虞山、虞山景区、尚湖景区）两日团建旅游活动，具体情况回顾如下`},
                {type:'text',center:`一、活动内容
                1、活动的总旨：
                1）不局限一个固定地点而做团建项目，年轻人要有飞扬的个性，大家要跑起来。
                2）真正的团队合作，成员之间互相信任、互相帮助、取长补短，我们的工作才会更顺利，我们的前途才更加光明。
                2、活动内容
                1)破冰分组：
                项目目标:　1：用特殊的形式在新的环境中重新认识与了解队友,打造良好的团队氛围；
                2：了解培训相关信息，做好心理准备。`},
                {type:'img',center:require("@/assets/img/News/newsImg3-1.png")},
                {type:'img',center:require("@/assets/img/News/newsImg3-2.png")},
                {type:'img',center:require("@/assets/img/News/newsImg3-3.png")},
                {type:'img',center:require("@/assets/img/News/newsImg3-4.png")},
                {type:'text',center:`2)奔跑吧·尚湖定向寻宝
                在尚湖不同的景点分布了11个任务点，每项任务考查点各不相同，完成的难易程度不同, 但都需要团队齐心协力才能成功完成。项目收获：全员参与，高度集中专注力，不断尝试，直到成功的喜悦。大家为了完成所有任务,也是蛮拼的…看图吧`},
                {type:'img',center:require("@/assets/img/News/newsImg3-5.png")},
                {type:'img',center:require("@/assets/img/News/newsImg3-6.png")},
                {type:'img',center:require("@/assets/img/News/newsImg3-7.png")},
                {type:'img',center:require("@/assets/img/News/newsImg3-8.png")},
                {type:'text',center:`队员因“教练说”游戏失败，被拉上去做惩罚——那妖娆的动作配上那句“讨厌”，让在场员工笑喷了`},
                {type:'img',center:require("@/assets/img/News/newsImg3-9.png")},
                {type:'img',center:require("@/assets/img/News/newsImg3-10.png")},
                {type:'img',center:require("@/assets/img/News/newsImg3-11.png")},
                {type:'img',center:require("@/assets/img/News/newsImg3-12.png")},
                {type:'img',center:require("@/assets/img/News/newsImg3-13.png")},
                {type:'img',center:require("@/assets/img/News/newsImg3-14.png")},
                {type:'img',center:require("@/assets/img/News/newsImg3-15.png")},
                {type:'text',center:`大家都专注于游戏中, 合力筑塔, 冠军团队合影, 定向寻宝——冠军团队产生，大家笑得合不拢嘴`},
                {type:'text',center:`3）虞山自由行
                团队自由的漫步于素有“七溪流水皆通海，十里青山半入城”美称的虞山风景区，与虞山、古城、山南尚湖融为一体，欣赏自然山水，品味人文景观。`},
                {type:'img',center:require("@/assets/img/News/newsImg3-16.png")},
                {type:'img',center:require("@/assets/img/News/newsImg3-17.png")},
                {type:'text',center:`4）入住常熟裕坤丽呈华廷酒店`},
                {type:'img',center:require("@/assets/img/News/newsImg3-18.png")},
                {type:'img',center:require("@/assets/img/News/newsImg3-19.png")},
                {type:'text',center:`一个第一次在闲暇之余聚集了这么多久乐人的地方，可以自由组团鬼哭狼嚎、可以半夜还在玩狼人杀游戏、可以随心所欲找好朋友、好同事畅聊到半夜的地方。`},
                {type:'text',center:`3.活动感受
                对于公司对此活动的精心安排，大家感受颇深，在这个团建里，每个人都是闪耀的小星星。因为我们赋予了热情，默契，更重要的是勇气和努力，创造了属于我们的闪耀时刻！`},
                {type:'text',center:`二.活动目标的达成度
                我们当初的设定，完全定格在框架里：
             团队凝聚力：公司是一个集体，只有所有员工团结一心、紧密合作，才能完成公司的使命，实现企业的目标。缓解工作压力：徒步在风景秀丽、空气清新的尚湖风景区，并进行园内寻宝，释放工作压力，开拓思维，培养团队合作精神，达到放松身心、劳逸结合的效果。
             燃烧激情：树立团队成员对团队目标的一致性，创新意识，激发团队实现目标的激情，为下面工作做铺垫。
             执行力：它是把目标变更为结果的行为，是一个结果，一种态度，也是一种习惯。它体现的是一种负责、敬业的精神，一种服从、诚实的态度。`},
             {type:'text',center:`增强员工归属感：只有感到归属，员工才能更加积极，真正地为公司的发展做出贡献。通过团队建设活动，员工间加深了解，拥有了这个大家庭的归属感，同时也真正感受到了公司的关心和支持，这很重要。
             通过此次团建活动把不同背景、不同个性、不同职场和不同经验的人组织在一起，使他们成为一个富有成效的工作团队，既丰富了娱乐生活，同时也塑造了一定的团队力量。我们学会了利用团队的气力，让每一个队员都发挥出自己的本事，通过互补打造出一个完美的团队。每当队员顺利完成挑战之后，我们的内心体会到了成功的喜欢和骄傲感。我们感受到了一个团队的凝聚力是多么的重要，汗水与泪水，痛楚与欢乐交织在一起。
             此次活动从开始立项到执行结束，都充分体现了同事之间互帮互助的团结精神，在整个活动过程，各位同事的极力配合使得整个活动能得以圆满结束，希望下次我们的团建活动举办得更加完美精彩。`},
             {type:'img',center:require("@/assets/img/News/newsImg3.png")},
            ]

        },

        {   
            id:4,
            url: require("@/assets/img/News/newsImg4.png"),
            title:'久乐电子召开2022年度股东会暨第一届董事会第12次会议',
            abstract: ` 8月16日，久乐电子在上海总部召开了2022年度股东大会暨第一届董事会第12次会议。股东代表、董事会及监事会成员出席会议，会议由久乐电子董事长兼总经理高劲松同志主持...`,
            release_time: '2023-08-16',
            subtitle: '--久乐电子在上海总部召开了2022年度股东大会',
            main_body_data:[
                {type:'text',center:`8月16日，久乐电子在上海总部召开了2022年度股东大会暨第一届董事会第12次会议。股东代表、董事会及监事会成员出席会议，会议由久乐电子董事长兼总经理高劲松同志主持。`},
                {type:'text',center:`会上，董事长兼总经理高劲松同志对各股东单位、董事、监事长期以来对久乐电子公司的大力支持表示感谢。高总分别从公司业务发展、内部管理等方面，全面总结了公司2022年度工作完成情况。会议按照议程召开，审议通过了《2022年度总经理报告》、《2022年度财务决算报告》、《2023年度财务预算报告》等相关股东会、董事会议题。公司股东对久乐电子在2022年疫情及全球芯片严重短缺的情况下，以研发替代、创新为基础，以快速生产为保障，积极推进各项工作和目标，对2022年的经营成果给予了充分肯定。`},
                {type:'text',center:`久乐电子将根据此次会议精神，认真做好股东会、董事会决议事项的贯彻落实，继续保持稳中有进，进中向好的发展态势。`},
                {type:'img',center:require("@/assets/img/News/newsImg4.png")},
                
            ]

        },


        {   
            id:5,
            url: require("@/assets/img/News/newsImg5.png"),
            title:'在关怀中砥砺前行',
            abstract: ` 2023年10月24日下午，市委书记盛文军及政府成员等一行领导莅临东方久乐汽车电子（上海）股份有限公司潜江分公司进行实地调研，公司董事长兼总经理高劲松陪同市领导参观了厂区在建项目及生产车间等...`,
            release_time: '2023-10-24',
            subtitle: '----潜江市领导盛文军一行实地调研东久电子',
            main_body_data:[
                {type:'text',center:`2023年10月24日下午，市委书记盛文军及政府成员等一行领导莅临东方久乐汽车电子（上海）股份有限公司潜江分公司进行实地调研，公司董事长兼总经理高劲松陪同市领导参观了厂区在建项目及生产车间等。`},
                {type:'img',center:require("@/assets/img/News/newsImg5.png")},
                {type:'text',center:`董事长高劲松就东久电子厂区建设情况及公司发展规划向盛书记一行领导进行了汇报：厂区总占地面积100亩，一期项目于2021年12月投入使用，2022年产值达到3.4亿元，实现税收1200万元。二期项目已完成50亩建设用地摘牌和土地平整。东久电子立足企业品牌和产品优势，制订切实可行的发展战略，推动传统产业转型升级，助力新兴产业加快发展。`},
                {type:'text',center:`盛文军书记深入生产车间，实地参观企业生产线和产品展示，总经理高劲松向参观领导详细介绍了企业生产操作流程、技术优势和生产力。盛书记鼓励企业要坚定信心，抢抓机遇，进一步拓展市场，提高产品质量和竞争力。`},
                {type:'img',center:require("@/assets/img/News/newsImg5-1.png")},
                {type:'text',center:`通过此次调研活动，盛文军书记对企业在技术创新、产品研发、市场开拓等方面所取得的成绩给予了充分肯定，并表示政府将一如既往地支持企业发展，积极帮助企业解决实际困难和问题。同时，他希望企业能够继续加强内部管理，提高产品质量和竞争力，为地方经济发展做出更大的贡献。`},
                
            ]

        },

        {   
            id:6,
            url: require("@/assets/img/News/newsImg6.png"),
            title:'河北新乐厂区开业典礼',
            abstract: ` 在这丰收的季节，我们欢聚一堂，共同见证一个历史性的时刻------东方久乐汽车电子（上海）股份有限公司新乐分公司新厂区于2023年11月9日上午举行了盛大的开业典礼仪式。`,
            release_time: '2023-11-09',
            subtitle: '----东久电子新乐新厂区开业',
            main_body_data:[
                {type:'text',center:`在这丰收的季节，我们欢聚一堂，共同见证一个历史性的时刻------东方久乐汽车电子（上海）股份有限公司新乐分公司新厂区于2023年11月9日上午举行了盛大的开业典礼仪式。莅临现场的多位领导和嘉宾与我们共同见证我们东久电子开业典礼这盛大时刻！`},
                // {type:'img',center:require("@/assets/img/News/newsImg6.png")},
                {type:'video', short_video:require("@/assets/video/新乐新厂区开业01.mp4"), leng_video:require("@/assets/video/新乐新厂区开业01.mp4"), pic: require("@/assets/img/News/newsImg6.png")},
                // {type:'text',center:`莅临现场的多位领导和嘉宾与我们共同见证我们东久电子开业典礼这盛大时刻！`},
                // {type:'img',center:require("@/assets/img/News/newsImg6-1.png")},
                {type:'text',center:`公司董事长高劲松高总致辞：新乐分公司是东久电子第一个生产基地，具备最完善的生产支持部门，具有公司最强的技术部门，为公司11年的发展做出了突出的贡献，经历了多次的考验，尤其疫情期间展现出了一支最能吃苦最能战斗的队伍，经历了11年的奋斗我们终于在新乐有了自己的生产基地，为下一步发展打下了坚实基础......，并宣布开业典礼正式开始!`},
                {type:'img',center:require("@/assets/img/News/newsImg6-2.png")},
                {type:'text',center:`新乐分公司新厂区项目项目总投资额为4亿元，占地面积3.3万平方米。新厂区将拥有先进的生产线、技术人才和经验丰富的生产人员，将为客户提供更高品质的产品和服务。`},
                {type:'text',center:`东久电子各位副总上台致辞宣誓`},
                {type:'img',center:require("@/assets/img/News/newsImg6-3.png")},
                {type:'text',center:`新乐分公司新厂区的建立，是我们公司发展历程中的一个里程碑，也是我们对于未来更美好生活的坚定信念的体现。这不仅意味着我们将有更大的生产能力，更好的工作环境，更意味着我们将以更高的标准，更优的服务，更创新的科技来服务我们的客户，回馈我们的社会。`},
                {type:'text',center:`各位部长和主管们上台致辞：`},
                {type:'img',center:require("@/assets/img/News/newsImg6-4.png")},
                {type:'text',center:`台上部长和主管们激情澎湃、斗志昂扬，趁着此刻的心情，一同宣誓：东久电子  扬帆起航,    东久电子   再创辉煌`},
                {type:'img',center:require("@/assets/img/News/newsImg6-5.png")},
                {type:'img',center:require("@/assets/img/News/newsImg6-6.png")},

                {type:'text',center:`为了感谢我们东久人的默默付出，高总特意为我们东久人发放了红包，感谢高总的认可，并激励东久人在以后得工作中更加努力奋斗!`},
                {type:'img',center:require("@/assets/img/News/newsImg6-7.png")},
                {type:'img',center:require("@/assets/img/News/newsImg6-8.png")},
                {type:'img',center:require("@/assets/img/News/newsImg6-9.png")},
                {type:'text',center:`东方久乐汽车电子（上海）股份有限公司成立11年，从一条产线到现在的17条产线，从8人到700多人，从10多万套产量到900多万套产量，离不开所有支持、参与新厂区建设的各级领导和工作人员的辛勤工作和无私奉献，同时我们要感谢我们的客户和合作伙伴们对我们持续的支持和信任，更要感谢十几载默默付出，勤恳工作的东久人，是大家的努力和坚持成就了东久电子的辉煌。`},
                {type:'text',center:`11响礼炮的鸣响，承载着我们共同的使命愿景重新远扬启航，这是我们新的起点...`},
                {type:'img',center:require("@/assets/img/News/newsImg6-10.png")},

                {type:'text',center:`新乐分公司新厂区的建成，是机遇也是挑战，我们将继续坚持务实、高效、开拓、创新的工作作风，坚持技术创新、精益管理、专注汽车电子、提升客户价值的质量方针，秉承顾客至上、信誉第一的企业宗旨，为打造国内一流国际知名的汽车电子企业而努力奋斗！`},
                {type:'img',center:require("@/assets/img/News/newsImg6-11.png")},
               
            ]

        },

        {   
            id:7,
            url: require("@/assets/img/News/newsImg7.png"),
            title:'新乐市人大常委会副主任一行到新乐分公司视察',
            abstract: ` 11月17日上午，新乐市人大常委会副主任史战英一行到东久电子所属新乐分公司视察，参观公司新厂区并听取公司项目建设工作开展情况汇报。新乐市有关人大代表及相关单位，新乐分公司行政部主管王志拥陪同视察。`,
            release_time: '2023-11-18',
            subtitle: '----新乐市人大常委会副主任史战英一行到东久电子所属新乐分公司视察',
            main_body_data:[
                {type:'text',center:`11月17日上午，新乐市人大常委会副主任史战英一行到东久电子所属新乐分公司视察，参观公司新厂区并听取公司项目建设工作开展情况汇报。新乐市有关人大代表及相关单位，新乐分公司行政部主管王志拥陪同视察。`},
                {type:'img',center:require("@/assets/img/News/newsImg7.png")},
                {type:'text',center:`史战英一行视察项目现场后，详细了解了公司发展历程、生产研发、工艺流程等情况。史战英强调，一要抢抓发展机遇，把握市场需求，及时发现有利机会，坚定不移走好高质量发展之路。二要抓紧加大生产规模，切实加大研发投入，实现生产效益最大化。三要加大市场开拓力度，深耕现有业务市场，打响自主品牌在市场上的知名度。`},
                {type:'img',center:require("@/assets/img/News/newsImg7-1.png")},
                {type:'text',center:`王志拥汇报了东久电子近年来重点工作进展情况、下一步工作思路等。他表示，东久电子下一步将加强核心技术攻关工作力度，继续做好研发、质量工艺、精益化管理等工作，借助资本市场加速推进公司发展。项目建成后将大力推进我市汽车电子核心技术发展，有利于推动本地高端装备制造产业发展，将为县域经济实现高质量发展打下坚实基础。`},
                
            ]

        },


        {   
            id:8,
            url: require("@/assets/img/News/newsImg8.png"),
            title:'雪寒人心暖 除雪暖意融',
            abstract: ` 12月14日，雪花飘然而至，新乐市普降大雪。一夜间，银装素裹，更增添了几分冬日的气息。为确保员工工作出行安全，第一时间，新厂区领导以身作则，积极号召和组织扫雪活动，我厂区员工积极响应，不畏严寒，纷纷投入到扫雪活动中，形成了这季里最美的风景线！`,
            release_time: '2023-12-15',
            subtitle: '----记新乐分公司新厂区开展员工扫雪除冰活动',
            main_body_data:[
                {type:'text',center:`12月14日，雪花飘然而至，新乐市普降大雪。一夜间，银装素裹，更增添了几分冬日的气息。为确保员工工作出行安全，第一时间，新厂区领导以身作则，积极号召和组织扫雪活动，我厂区员工积极响应，不畏严寒，纷纷投入到扫雪活动中，形成了这季里最美的风景线！`},
                {type:'img',center:require("@/assets/img/News/newsImg8.png")},
                {type:'text',center:`凛冽的寒风、零下的低温、结冰的路面，使清扫工作变的十分艰难，许多人的手和脸被冻得通红，然而，这一切丝毫没有影响大家的劳动热情，他们团结协作，井然有序，扫的扫、铲的铲，干得热火朝天。经过奋战，新厂区内外道路上的积雪被打扫得干干净净，确保了公司员工的出行安全。`},
                {type:'img',center:require("@/assets/img/News/newsImg8-1.png")},
                {type:'text',center:`大家扫去的是雪，带来的更加是东久人乐观向上、顽强拼博的奋斗精神。相信在这个充满爱心的厂区里，有奉献相伴，冬天不会冷。`},
                {type:'img',center:require("@/assets/img/News/newsImg8-2.png")}
                
            ]

        },

        
        {   
            id:9,
            url: require("@/assets/img/News/newsImg9.png"),
            title:'新乐市市委李书记赴东久电子新乐分公司现场调研',
            abstract: ` 2月27日上午，新乐市市委李书记带领市科工局、发改局、税务局、自规局等相关部门负责同志一行赴东久电子所属新乐分公司现场调研，参观公司新厂区并深入车间实地考察了解生产经营情况。分公司厂长赵一樊等相关人员陪同此次调研活动。`,
            release_time: '2024-02-28',
            subtitle: '----新乐市市委李书记赴东久电子新乐分公司现场调研',
            main_body_data:[
                {type:'text',center:`2月27日上午，新乐市市委李书记带领市科工局、发改局、税务局、自规局等相关部门负责同志一行赴东久电子所属新乐分公司现场调研，参观公司新厂区并深入车间实地考察了解生产经营情况。分公司厂长赵一樊等相关人员陪同此次调研活动。`},
                {type:'img',center:require("@/assets/img/News/newsImg9.png")},
                {type:'text',center:`李书记一行在公司生产车间实地考察参观，厂长赵一樊向一行人详细介绍汇报企业生产经营、市场销售、改革创新、安全生产、环境保护等工作，李书记听取汇报后对公司的发展给予高度评价，并就企业下一步高质量发展提出指导意见。`},
                {type:'img',center:require("@/assets/img/News/newsImg9-1.png")},
                {type:'text',center:`李书记强调，重点工业企业是市场经济发展的中流砥柱，是推动经济社会高质量发展的重要主体和抓手。希望新乐分公司在今后工作中应抢抓市场机遇，加大生产研发，扩大生产规模，提高产品质量，打造特色品牌，形成品牌效应，推动产业集群集聚发展，不断提升企业发展的质量和效益，为新乐市高质量发展提供重要支撑。`}, 
            ]

        },

        {   
            id:10,
            url: require("@/assets/img/News/newsImg10.png"),
            title:'长安汽车专家团队赴新乐分公司考察 携手共筑品质座驾基石',
            abstract: ` 12月16日，长安汽车一支由技术、质量专家组成的精英团队专程前往东久电子新乐分公司，针对座椅控制器产品展开深度考察与交流, 旨在进一步优化汽车座椅功能，提升驾乘体验，为双方深度合作筑牢根基。总公司副总经理杨超陪同考察。`,
            release_time: '2024-12-17',
            subtitle: '----新乐分公司行政部  供稿',
            main_body_data:[
                {type:'text',center:`12月16日，长安汽车一支由技术、质量专家组成的精英团队专程前往东久电子新乐分公司，针对座椅控制器产品展开深度考察与交流，旨在进一步优化汽车座椅功能，提升驾乘体验，为双方深度合作筑牢根基。总公司副总经理杨超陪同考察。`},
                {type:'img',center:require("@/assets/img/News/newsImg10.png")},
                {type:'text',center:`抵达公司后，专家团队径直深入生产一线，先后考察了样品陈列区、文化宣传区、仓储物流区、生产车间等。在座椅控制器生产区域，现代化的生产线有序运转，工人们严谨细致地操作着各类精密设备，从芯片贴片、电路焊接到外壳组装，一道道工序精准流畅。分公司负责人详细讲解每一个生产环节的工艺管控，着重展示了自动化检测设备如何精准剔除不良品，确保产品零缺陷率，长安专家们不时驻足询问，对生产流程的精细化程度给予高度肯定。`},
                {type:'img',center:require("@/assets/img/News/newsImg10-1.png")},
                {type:'text',center:`随后会上，双方围绕座椅控制器技术创新与应用、本次评估过程中发现的不足与问题及相关人员廉洁跟踪开展研讨。东久汽车电子的研发团队分享了最新研发成果，如针对座椅多向电动调节的智能算法优化，能实现更顺滑、精准的操控；还有在控制器节能减耗方面的突破，延长电池续航表现。长安汽车专家则依据市场反馈及整车适配需求，提出个性化定制建议，期望在控制器联动调节上更趋智能人性化，双方思维碰撞，挖掘诸多合作创新点。`}, 
                {type:'img',center:require("@/assets/img/News/newsImg10-2.png")},
                {type:'text',center:`长安汽车代表表示：“座椅控制器虽小，却关乎车内舒适度关键体验，东久电子展现出的专业制造实力与研发活力令人瞩目，期待以此考察为契机，深化合作，共同打造行业领先的座椅控制系统。”此次考察为双方后续紧密协作、联合攻关明晰方向，有望推动汽车座椅智能化变革迈向新征程。`}, 
            ]

        },




    ]
})



Mock.mock("/index.php/news-ind-list","get",{
    code: 0,//错误码
    msg: "",//错误消息
    //data为具体的消息内容，如果code不为0，则必为null
    data: [
        {   
            id:1,
            url: require("@/assets/img/News/newsIndImg1.png"),
            title:'中国一汽成立70周年特别报道│中国汽车工业暨一汽迎来70周年华诞',
            abstract: ` 7月15日，中国汽车工业迎来70周年华诞，“共和国长子”中国一汽也迎来成立70周年纪念日，一汽在吉林省长春市举办大会纪念这一时刻。当日，一汽生产的第5577万辆汽车在长春下线...`,
            release_time: '2023-07-15 21:35 发表于北京',
            source: '新华网汽车',
            iframe_url: 'https://mp.weixin.qq.com/s/uJ8wCHhHoJWeT7MQu21FPA',
        },

        {   
            id:2,
            url: require("@/assets/img/News/newsIndImg2.png"),
            title:'15.98万起售，比预售便宜1万，2023款比亚迪宋PLUS DM-i冠军版上市',
            abstract: ` 要问当下汽车市场销量最好的品牌是哪家？答案一定是比亚迪，再问比亚迪卖的最好的车是哪一款？宋PLUS绝对是其中之一。根据比亚迪最新销量数据，宋PLUS系列车型6月销量达到25935辆，上半年累计销量达到18.42万辆，不仅成为比亚迪海洋网的一大热门车型，更是支撑比亚迪上半年销量突破124.8万辆，继续稳坐乘用车冠军宝座...`,
            release_time: '2023-07-17 18:56 发表于江西',
            source: '米邦车讯',
            iframe_url: 'https://mp.weixin.qq.com/s/6C8vN6kKO1MmsKbkpprKdg',
        },

        {   
            id:3,
            url: require("@/assets/img/News/newsIndImg3.png"),
            title:'2023年9月份乘用车销量',
            abstract: ` 2023年9月乘用车市场零售达到201.8万辆，同比增长5.0%，环比增长5.0%。今年以来累计零售1523.3万辆，同比增长2.4%。...`,
            release_time: '2023-10-11 20:20 发表于广东',
            source: '一览众车',
            iframe_url: 'https://mp.weixin.qq.com/s/RYlxpJyJRYPjuvD_zZwz8g',
        },

        {   
            id:4,
            url: require("@/assets/img/News/newsIndImg4.png"),
            title:'企业生产忙 力争“开门红”',
            abstract: ` 1月2日，在河北省石家庄新乐市东方久乐汽车电子（上海）股份有限公司新乐分公司生产车间，工人在生产线加紧生产出口订单。新年伊始，各地企业开足马力赶订单、忙生产，力争“开门红”。`,
            release_time: '2025-01-03 16:37发表于新华网',
            source: '新华网',
            iframe_url: 'https://www.news.cn/photo/20250103/346a2a8180f1496db0333e55b50b455d/c.html?page=14&sid_for_share=99125_3',
        },


        {   
            id:5,
            url: require("@/assets/img/News/newsIndImg5.png"),
            title:'李明政调研重点工业企业节后复工复产情况”',
            abstract: ` 2月5日，市委书记李明政到部分重点工业企业调研了复工复产情况。李明政先后到久乐气囊、久乐电子、利华药业、博宇氨基酸、奥星药业等工业企业进行实地调研。每到一处，李明政都与企业负责人亲切交谈，认真听取他们对企业复工复产情况的介绍，详细了解企业生产经营、销售市场及新一年发展方向等情况。`,
            release_time: '2025年02月05日 18:03 河北 新乐市发布',
            source: '新乐市发布',
            iframe_url: 'https://mp.weixin.qq.com/s/Vvizl-15tu3-udMbOC41FA',
        },

        {   
            id:6,
            url: require("@/assets/img/News/newsIndImg6.png"),
            title:'2024-2030年汽车低压锂电池市场及企业调研报告”',
            abstract: `相对铅酸电池，锂电池能量密度更大，循环寿命更长，同时金属锂对环境的污染相对较小。在汽车低压电池领域，锂电的应用也越来越广泛，虽然从实际情况来看，锂电对铅酸的进展相对缓慢，但趋势已显，未来仍有较大的市场空间。`,
            release_time: '原创 一览众咨询 一览众车2025年03月11日 10:22 广东',
            source: '一览众车',
            iframe_url: 'https://mp.weixin.qq.com/s/WGL0tQOFHydNXWL47LEpNA',
        },


        {   
            id:7,
            url: require("@/assets/img/News/newsIndImg7.png"),
            title:'2024-2030年新能源汽车热管理市场及企业调研报告”',
            abstract: `新能源汽车的发展，对于汽车热管理系统是一场大的变革。传统燃油车的热管理架构主要包括了空调系统以及动力总成热管理系统。新能源汽车由于动力源发生了变化，新增了三电系统，因此要对电池、电机、电控等进行热管理的重新构建。`,
            release_time: ' 一览众咨询 一览众车 2025年03月10日 21:43 广东',
            source: '一览众车',
            iframe_url: 'https://mp.weixin.qq.com/s/89sAa-FAMJTCL85Z5zz-vw',
        },


        {   
            id:8,
            url: require("@/assets/img/News/newsIndImg8.png"),
            title:'从“百舸争流”到“七雄争霸”，中国汽车业大整合催生全球竞争新秩序”',
            abstract: `3月29日，国务院国资委相关负责人在中国电动汽车百人会论坛上表示，下一步将对整车央企进行战略性重组，提高产业集中度。战略性重组的目标是集中央企的研发制造和市场等优势资源，打造具有全球竞争力、拥有自主核心技术，引领智能网联变革的世界一流汽车集团。此外，还将鼓励央企加大其他合作`,
            release_time: ' 新华汽车网 2025年03月31日 13:00 北京',
            source: '新华汽车网',
            iframe_url: 'https://mp.weixin.qq.com/s/LqT8F01d9KXoBJkAqYBb_g',
        },

    ]
})