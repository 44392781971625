<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-22 16:56:12
-->
<template>
  <div class="enthonor-container">

    <div class="submenu-wrapper">
      <Submenu 
      :engLabel="englabel"
      :cnLabel="cnLabel"
      :linkData="linkData"
    />
    </div>

    <div class="main-wrapper py-2 py-md-5 px-2">
      <!-- 东久电子取得的主要成绩及荣誉 -->
        <div class="company-honor my-2 my-md-5">
          <h3 class="overview-title">东久电子取得的主要成绩及荣誉</h3>
          <div class="row align-items-start justify-content-center">
            <div class="col-12 col-md-8 honor-introduct mt-5 my-md-0 mx-2 mx-md-0">
               
              <h4 class="my-2">公司荣誉:</h4>
              <p class="introduce-paragraph" v-for="par,index in paragraphList" :key="index">{{ par }}</p>
           </div>
          </div>

        </div>


      <!-- 客户荣誉 -->
       <div class="img-wrapper">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10">
              <h4 class="my-2">来自客户及政府授予荣誉:</h4>
              <div class="my-2 pd-2 pd-md-3 honor-wrapper" v-for="item in honorData" :key="item.id">
                <div class="row  my-2 mx-my-4 mx-1 mx-md-4 justify-content-center" >

                    <p class="split-header">{{item.text}}</p>
                    <div class="col-12 col-md-3 my-2 mx-my-4 mx-1 mx-md-4" v-for="items,index in item.img" :key="index">

                      <ImagePreview
                      :src="items"
                      :height="238"
                      :previewurl="getPreviewurl(items)"
                      fit="scale-down"
                      />
                    </div> 

                    </div>
              </div>
         


            </div> 
         </div>
        </div>




        <!-- 企业荣誉 -->
        <div class="img-wrapper ">
          <p class="split-header">企业荣誉</p>
          <div class="row justify-content-center">
            <div class="col-12 col-xl-10">
              <ImageLoader 
                :src="img"
                :height="imgHeight"
                fit="fill"
                />
            </div> 
         </div>
        </div>

      </div>

  </div>
</template>

<script>
import { AboutLink } from "@/assets/js/submenulink.js";
import { getHonorIntroduct } from "@/api/enterpHonor.js";
import Submenu from "@/components/Submenu/";
import ImageLoader from "@/components/ImageLoader/";
import ImagePreview from "@/components/ImagePreview";
export default {
   components: {
    Submenu,
    ImageLoader,
    ImagePreview,
   },
  
   data() {
    return {
      englabel: 'ABOUT US',
      cnLabel: '关于东久电子',

      honorData:[],//企业荣誉

      imgHeight: 568,
      img_aspect_ratio: (1237 / 516).toFixed(2),
      windowSize: {},
      linkData: [],
      img: require("@/assets/img/About/qiyerongyu/qiyerongyu.png"),

      paragraphList: [
     " 2013年2月，东久电子和新乐分公司通过ISO/TS16949:2009质量体系认证；",
     " 2013年9月，东久电子认定为上海市高新技术企业；",
     " 2013年10月，产品车窗防夹控制器（APM）认定为上海市高新技术成果转化项目；",
      "2015年12月，东久电子认定为小巨人培育企业；",
     " 2015年7月，东久电子认定为“专精特新”企业；",
      "2016年9月，东久电子高新技术企业复审通过，并加入上海市高新技术企业协会；",
     " 2016年通过上海市科技小巨人（培育）企业认定，并于2018年3月顺利验收；",
     " 2017年7月，东久电子“专精特新”企业复审通过；",
     " 2017年3月，车身控制器软件V1.0（BCM）申请为软件著作权；",
     " 2017年承担上海市经信委汽车和工业MEMS高精度惯性和卫星组合导航系统项目科研；",
     " 2018年3月，东久电子认定为浦东新区研发机构；",
      "2019年通过上海市高新技术企业复审继续获得高新技术企业认定；",
      "2021年8月通过工业强基项目-汽车和工业用MEMS高精度惯性和卫星组合导航系统的验收；",
     " 2021年通过国家工信部专精特新“小巨人”企业认定",
      "2022年认定为“小巨人”企业",
      "2022年东久电子“专精特新”企业复审通过；",
     " 2022年1月，安全气囊控制器V1.0申请为软件著作权；",
      "2022年3月，车窗防夹控制器V1.0申请为软件著作权；",
     " 2022年3月，座椅控制器V1.0申请为软件著作权；",
      "2022年，东久电子认定为科技小巨人企业；",
      "2022年12月，东久电子复核通过高新技术企业认定；",
     " 2023年6月，东久电子认定为浦东新区战略新兴产业培育企业；",
      "2023年9月，东久电子通过CNAS实验室认证；",
      "2024年1月，东久电子认定为浦东新区营运总部；",
      "2024年5月，东久电子获得上海专精特新“小巨人”企业品牌价值榜“百佳企业”称号；",
      "2024年9月，东久电子通过工信部的专精特新“小巨人”企业复核；",
     " 2025年2月，东久电子获得浦东新区合庆镇政府颁发的合庆领航奖。",

      ]
    }
  },

  created() {
      this.getWindowSize();
      this.linkData = AboutLink;
      this.getHonorData();
   },

  mounted() {
      //拉伸窗口时触发
      window.onresize = () => {
          this.getWindowSize();
          
      }

  },
   methods: {

    getHonorData() {
      getHonorIntroduct().then(res => {
              this.honorData = res.reverse();
            });
        },
    getWindowSize() {
         this.windowSize.width =   document.documentElement.clientWidth || document.body.clientWidth; 
         this.windowSize.Height =  document.documentElement.clientHeight || document.body.clientHeight; 
         this.imgHeight = Math.round(this.windowSize.width / this.img_aspect_ratio); 
        },

  getPreviewurl(item){
    return [item];
  },
   }


}
</script>

<style scoped lang="less">
@import "~@/styles/global.less";
  .enthonor-container{
    position: relative;;
    width: 100%;;
    height: auto;
    .general-layout

 

    .company-honor{
      position: relative;
      width: 100%;
      height: auto;

    }

    .overview-title{
      text-align: center;
      font-weight: 700;
      color: black !important;
    }

    h4{
       font-weight: bold;
       color:@words;
       margin-top: 5rem;
       margin-bottom: 2rem;
    }

    .honor-wrapper{
      padding: 3rem 0;
      border-bottom: 1px solid @words;
    }


    .img-wrapper{
      margin:5rem 0;

      h4{
        text-align: center;
      }
    }

    
  }

</style>